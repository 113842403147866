

























































































































































































































































































































import { mapState } from 'vuex'
import SplitViewLayout from '@/components/SplitViewLayout.vue'
import SplitView from '@components/SplitView.vue'
import StandardReport from '@router/views/StdReport.vue'
import TeleconsultationDetail from '@router/views/TeleconsultationDetail.vue'
import MetaColumn from '@store/models/base/TableMetaColumn'
import ListMixin from '@mixins/list.js'
import CacheFilter from '@mixins/cachedFilter.js'
import reportService from '@services/reportService'
import { menu } from '@services/menu'
import api from '@services/api'
import consultations from '@services/consultationService'

const defaultFilter = {
	keywordString: '',
	order: {
		by: 'requestDate',
		isAscending: false,
	},
	modalityIds: [],
	studyStartDate: '',
	studyEndDate: '',
	responseStartDate: '',
	responseEndDate: '',
	consultantId: null,
	species: '',
	requestedBy: null
}

export default {
	name: 'ReportDeepSearch',
	components: {
		SplitViewLayout,
		SplitView,
		StandardReport,
		TeleconsultationDetail,
	},
	mixins: [ListMixin, CacheFilter],
	props: {
		isTeleconsultation: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			menu,
			selectedRow: null,
			filter: JSON.parse(JSON.stringify(defaultFilter)),
			speciesNames: [],
		}
	},
	computed: {
		...mapState({
			claims: (state: any) => state.auth.claims,
			isConsultantApprovalSupported: (state: any) => state.auth.claims.isConsultantApprovalSupported,
			clientClinics: state => state.consultant.clientClinics,
		}),
		keywords() {
			return [
				...new Set(
					this.filter.keywordString
						.split(/[\s|,]+/g)
						.map(k => k.trim())
						.filter(k => !!k)
				),
			]
		},
		columns() {
			const columns = [
				{}, // View button
				this.isTeleconsultation ? MetaColumn('Type') : null,
				{
					name: this.isTeleconsultation ? 'Request Date' : 'Report Date',
					columnName: 'requestDate',
					isSortable: true,
				},
				this.isTeleconsultation ? MetaColumn('Consultant') : null,
				this.isTeleconsultation && this.isConsultantApprovalSupported ? MetaColumn('Drafted By') : null,
				MetaColumn({ name: this.isTeleconsultation ? 'Client' : 'Consultant', sortName: 'RequestingDoctor' }),
				this.isTeleconsultation ? MetaColumn('Clinic') : null,
				MetaColumn('Patient Id'),
				MetaColumn({ name: 'Patient', sortName: 'PatientName' }),
				MetaColumn({ name: 'Owner', sortName: 'OwnerName' }),
				MetaColumn({ name: 'Modality', columnName: 'modality', sortName: 'ModalityId' }),
			].filter(x => !!x)
			return columns
		},
		consultants() {
			return this.$store.state.static.consultants
		},
		validSearch() {
			if (!this.keywords.length) return false
			if (this.isTeleconsultation && !this.filter.consultantId) return false
			return true
		},
		noResultsText() {
			if (this.validSearch) return 'No results found.'
			else if (this.isTeleconsultation && !this.filter.consultantId) {
				if (this.keywords.length) {
					return 'Please select a consultant.'
				} else return 'Select a consultant and enter at least one keyword to perform a search.'
			} else return 'Enter at least one keyword to perform a search.'
		},
		selectedRowTitle() {
			if (this.selectedRow) {
				return `${this.selectedRow.patientName} - ${this.selectedRow.patientId}`
			}
			return 'Details'
		},
	},
	watch: {
		list() {
			this.refreshSelectedRow()
		},
	},
	async created() {
		let consultantId = null
		if (this.isTeleconsultation && this.claims.isConsultantUser) {
			consultantId = this.claims.userId
		}

		if (consultantId) {
			let groups = await consultations.getConsultantGroups(consultantId)
			this.filter.consultantId = groups.length ? groups[0].id : consultantId
		}
		let speciesList = await api.patient.getSpecies(consultantId)
		this.speciesNames = speciesList.map(s => s.name)
	},
	methods: {
		async fetchList() {
			if (!this.validSearch) return

			const data = await reportService.deepSearch({
				...this.filter,
				keywords: this.keywords,
				page: this.currentPage || 0,
				results: this.resultsPerPage || 10,
				orderby: this.filter.order.by,
				isOrderAscending: this.filter.order.isAscending === true,
				consultantId: this.filter.consultantId,
			})

			if (data) {
				this.list = data.results
				this.hasMoreResults = data.hasMoreResults
			}
		},
		refreshSelectedRow() {
			const reportId = this.selectedRow?.consultantReportId || this.selectedRow?.reportId
			if (this.list && reportId) {
				this.selectedRow = this.list.find(r => [r.consultantReportId,r.reportId].includes(reportId))
			}
		},
		handleRowSelected(row) {
			this.selectedRow = row
			if (!row) return
			// Split view appearing could move selected row out of visible scroll area
			this.$nextTick(async () => {
				this.$refs.dataTable.scrollSelectedRowIntoView()
			})
		},
		async handleRowOpen(row, viewOnOpen = false) {
			this.isLoading = true // show loading spinner while teleconsultation detail loads
			const doneLoading = () => (this.isLoading = false)
			if (!row.consultantReportId) {
				this.$router.push(
					{
						name: 'standard-report',
						params: { id: row.reportId, keywords: this.keywords, viewOnOpen },
					},
					doneLoading, // hide loading spinner after routing completes or is aborted
					doneLoading
				)
				return
			}
			this.$router.push(
				{
					name: row.isSaleSubmission ? 'sale-submission' : row.isImageOnly ? 'image-only-report' : 'teleconsultation',
					params: { id: row.consultantReportId, keywords: this.keywords, viewOnOpen },
				},
				doneLoading, // hide loading spinner after routing completes or is aborted
				doneLoading
			)
		},
		async openReportAndViewer(row) {
			this.handleRowOpen(row, true)
		},
		onSplitViewClose() {
			this.selectedRow = null
			if (this.$refs.dataTable) this.$refs.dataTable.hideExpandedRow()
			this.$nextTick(() => this.toggleDrawerIfNeeded())
		},
		toggleDrawerIfNeeded() {
			const { isVertical, secondaryView } = this.$refs.splitView
			const drawerIsOpen = this.$store.state.ui.isPersistentDrawerOpen
			let action = ''

			// Hide persistent drawer if constrained in horizontal orientation
			// ...or reopen if it was closed and it shouldn't be any more
			if (secondaryView) {
				if (isVertical) {
					if (this.didHide) action = 'open'
				} else if (!this.mq.xLarge) {
					action = 'close'
				}
			} else {
				if (this.didHide) {
					action = 'open'
				}
			}
			if ((action === 'open' && !drawerIsOpen) || (action === 'close' && drawerIsOpen)) {
				this.$store.commit('TOGGLE_PERSISTENT_DRAWER')
				this.didHide = drawerIsOpen
			}
		},
	},
}
